<template>
  <div
    class="text-image-with-accordion"
    :class="[imagePositionClass, themeColorClass]"
  >
    <UiHeader
      :header="header"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <UiAccordion
      :list="tagList"
      :with-numbers="tagIcon"
    />

    <div class="text-image-with-accordion__image">
      <UiImg
        :image="image"
        use-image-size
      />

      <div
        v-if="subheader"
        class="text-image-with-accordion__image-extra"
        :class="extraVariantClass"
      >
        <IconLightbulb />
        <span v-text="subheader" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import IconLightbulb from '~ui/assets/new-icons/icon-lightbulb.svg'
import { UiAccordion, UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const themeColorClass = useTheming(props.themeColor)
const extraVariantClass = useTheming(props.themeColor, 'variant')

const imagePositionClass = computed(() => {
  return `text-image-with-accordion--image-${props.imagePosition || 'right'}`
})
</script>

<style lang="scss">
.text-image-with-accordion {
  display: grid;
  align-items: start;
  grid-template-rows: repeat(2, auto);

  & .ui-header,
  & .ui-accordion,
  &__image {
    grid-column: 1 / -1;
  }

  & .ui-header {
    grid-row: 2;
    margin-bottom: rem(40px);

    &__title {
      margin: 0;
    }
  }

  & .ui-accordion {
    grid-row: 3;
  }

  &__image {
    grid-row: 1;
    display: flex;
    flex-direction: column;
    gap: rem(40px);
    padding-bottom: rem(48px);

    &-extra {
      grid-column: 1 / -1;
      display: flex;
      gap: rem(12px);
      font-size: rem(18px);
      background-color: var(--variant__gradient-100);
      padding: rem(16px);
      border-radius: em(4px);
      color: var(--variant__font-color);

      @include media-query(max sm) {
        flex-direction: column;
      }

      & svg {
        flex-shrink: 0;

        & path {
          fill: var(--variant__theme-color);
        }
      }
    }
  }

  & .ui-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: rem(4px);
  }

  @include media-query(sm) {
    grid-template-columns: repeat(20, 1fr);

    & .ui-header {
      grid-row: 1;
    }

    & .ui-accordion {
      grid-row: 2;
    }

    &__image {
      grid-row: 2;
      padding-bottom: 0;
    }

    &--image-left .ui-header,
    &--image-left .ui-accordion {
      grid-column: 10 / -1;
    }

    &--image-left &__image {
      grid-column: 1 / 10;
      padding-right: rem(64px);
    }

    &--image-right .ui-header,
    &--image-right .ui-accordion {
      grid-column: 1 / 12;
    }

    &--image-right &__image {
      grid-column: 12 / -1;
      padding-left: rem(64px);
    }
  }
}
</style>
